<script setup lang="ts">
import type { Instagram } from "~/types";
// インスタグラム表示
const instagram = ref<Instagram>([]);

const instaRef = ref(null);
const { stop } = useIntersectionObserver(
  instaRef,
  async ([{ isIntersecting }]) => {
    if (isIntersecting) {
      const res = await $fetch("/api/instagram");
      if (res) {
        instagram.value = res;
      }
      stop();
    }
  },
);
</script>

<template>
  <div class="py-4 mb-8 tablet:mb-16" ref="instaRef">
    <!-- company -->
    <TextMinchoTitle>
      <span>Instagram</span>
      <span class="p-2 text-sm">公式インスタグラム</span>
    </TextMinchoTitle>

    <div class="wrapper flex gap-12 justify-center mb-8 tablet:mb-16 relative">
      <ul v-if="instagram && instagram.length" class="grid grid-cols-3 gap-4">
        <li
          v-for="item in instagram"
          :key="item.id"
          class="border border-gray-200 rounded-lg"
        >
          <NuxtLink :to="item.permalink">
            <ImageEl
              width="600"
              height="600"
              provider="customimgproxy"
              class="w-full rounded"
              :src="item.thumbnail_url ? item.thumbnail_url : item.media_url"
              :loading="'lazy'"
              :loadingAnimation="true"
              format="webp"
              resize="fill"
              gravity="ce"
              enlarge="1"
            />
          </NuxtLink>
        </li>
      </ul>
      <Loading
        v-else
        class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
      />
    </div>
  </div>
</template>
