<script setup lang="ts">
import dayjs from "dayjs";
interface Props {
  min?: string;
  max?: string;
}
const props = defineProps<Props>();

const today = dayjs();
const selectYM = ref(today.format("YYYY-MM"));

// ファミテイの休日と国民の祝日
const holidayData = ref<{ famitei: string[]; national: string[] }>({
  famitei: [],
  national: [],
});
// 取得処理
async function fetchData() {
  const [{ holiday: holiday_f }, holiday_n] = await Promise.all([
    $fetch(`/api/calendar/holiday?ym=${selectYM.value}`),
    $fetch(`/api/calendar/national?year=${selectYM.value}`),
  ]);

  if (holiday_f.length && holiday_n.length) {
    holidayData.value = { famitei: holiday_f, national: holiday_n };
  }
}

// カレンダーの要素
const calenderEl = ref<HTMLElement | null>(null);

// 要素が画面内に入ったらデータを取得させる
const { stop } = useIntersectionObserver(
  calenderEl,
  async ([{ isIntersecting }]) => {
    // 初めて画面内に入ったときにデータを取得
    if (isIntersecting) {
      await fetchData();
      // 監視の停止
      stop();
    }
  },
);

// ページめくり下限
const minYM = props.min || selectYM.value;

// ページめくり上限
const maxYM =
  props.max || dayjs(selectYM.value).add(1, "month").format("YYYY-MM");

// 曜日のリスト
const dayOfWeekJP = [
  { key: 0, value: "日" },
  { key: 1, value: "月" },
  { key: 2, value: "火" },
  { key: 3, value: "水" },
  { key: 4, value: "木" },
  { key: 5, value: "金" },
  { key: 6, value: "土" },
];

// カレンダーを設定
const calDates = computed(() => {
  const baseDate = dayjs(selectYM.value);
  const dayCount = baseDate.daysInMonth();
  const firstDay = baseDate.startOf("month");

  const daylist = [];
  // 初日が日曜日でなければ前月を生成
  if (firstDay.day() != 0) {
    for (let i = 0; i < firstDay.day(); i++) {
      const date = firstDay.subtract(i + 1, "day");
      daylist.push({
        date: date.format("YYYY-MM-DD"),
        year: date.year(),
        month: date.month() + 1,
        day: date.date(),
        dayofweek: dayOfWeekJP.find((e) => e.key == date.day())?.value ?? "",
      });
    }
    daylist.reverse();
  }

  // 今月を生成
  for (let i = 0; i < dayCount; i++) {
    const date = firstDay.add(i, "day");
    daylist.push({
      date: date.format("YYYY-MM-DD"),
      year: date.year(),
      month: date.month() + 1,
      day: date.date(),
      dayofweek: dayOfWeekJP.find((e) => e.key == date.day())?.value ?? "",
    });
  }

  // 当月最終日土曜でなければ次月を生成
  const nextDays = 6 - baseDate.endOf("month").day();
  if (0 < nextDays) {
    const next = baseDate.add(1, "month").startOf("month");
    for (let i = 0; i < nextDays; i++) {
      const date = next.add(i, "day");
      daylist.push({
        date: date.format("YYYY-MM-DD"),
        year: date.year(),
        month: date.month() + 1,
        day: date.date(),
        dayofweek: dayOfWeekJP.find((e) => e.key == date.day())?.value ?? "",
      });
    }
  }

  return daylist;
});

// ファミテイの休日か
function checkHoliday(date: string) {
  return holidayData.value.famitei.find((e: string) => e == date);
}

// 国民の祝日か
function checkNationalHoliday(date: string) {
  return holidayData.value.national.find((e: string) => e == date);
}

// カレンダーを前月へ
function prevCal() {
  if (!canPrev()) return;
  selectYM.value = dayjs(selectYM.value).subtract(1, "month").format("YYYY-MM");
}

// カレンダーを次月へ
function nextCal() {
  if (!canNext()) return;
  selectYM.value = dayjs(selectYM.value).add(1, "month").format("YYYY-MM");
}

// ページめくり下限 default:当月まで
function canPrev() {
  return dayjs(selectYM.value).isAfter(dayjs(minYM));
}

// ページめくり上限 default:2か月先まで
function canNext() {
  return dayjs(selectYM.value).isBefore(dayjs(maxYM));
}
</script>

<template>
  <div
    ref="calenderEl"
    class="calendar max-w-[500px] mx-auto select-none relative"
  >
    <div class="text-center mb-2">
      <p class="text-4xl">
        {{ dayjs(selectYM).month() + 1 }}<span class="text-sm">月</span>
      </p>
      <p class="text-lg">{{ dayjs(selectYM).year() }}</p>
    </div>
    <div class="absolute flex items-center justify-between w-full top-0">
      <button
        class="bg-gray-500 text-white px-2 py-1 hover:bg-gray-600 rounded"
        :class="!canPrev() ? 'cursor-not-allowed opacity-50' : 'hover-button'"
        @click="prevCal"
      >
        &lt
      </button>
      <button
        class="bg-gray-500 text-white px-2 py-1 hover:bg-gray-600 rounded"
        :class="!canNext() ? 'cursor-not-allowed opacity-50' : 'hover-button'"
        @click="nextCal"
      >
        >
      </button>
    </div>
    <div>
      <ul class="grid grid-cols-7">
        <li
          v-for="w in dayOfWeekJP"
          :key="w.value"
          class="border font-bold text-center bg-emerald-100 p-1"
          :class="{
            'text-red-500 rounded-tl': w.key == 0,
            'text-blue-500 rounded-tr': w.key == 6,
          }"
        >
          <span>{{ w.value }}</span>
        </li>
        <li
          v-for="cal in calDates"
          :key="cal.date"
          class="aspect-square border flex flex-col items-center justify-center relative"
          :class="{
            'border border-green-500': cal.date == today.format('YYYY-MM-DD'),
            'bg-orange-50': checkHoliday(cal.date),
          }"
        >
          <span
            class="absolute top-0 left-0 font-bold"
            :class="{
              'text-red-600':
                cal.dayofweek == '日' || checkNationalHoliday(cal.date),
              hidden:
                `${cal.year}-${cal.month.toString().padStart(2, '0')}` !=
                selectYM,
              'text-blue-600': cal.dayofweek == '土',
            }"
          >
            {{ cal.day }}</span
          >
          <span
            class="text-red-600 mt-2 font-bold text-center"
            v-if="checkHoliday(cal.date)"
            >休</span
          >
        </li>
      </ul>
    </div>
  </div>
</template>
<style scoped lang="scss">
.hover-button {
  @apply shadow hover:shadow-none hover:opacity-80;
}
</style>
