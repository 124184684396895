import type { TopSlideBanner } from "~/types";
import {
  plan,
  set_discounts,
  contest,
  mobi,
  campaign,
  mizu,
  cp_big_carport,
  cp_new_item,
} from "~/constants/top/slideBanner";
export default async function () {
  // 件数取得
  const { data: total } = await useTotalCount();

  const sekou: TopSlideBanner = {
    alt: "関西でNo1外構施工例実績。外構・リフォーム工事施工例を毎日更新中",
    text: {
      type: "sekou",
      value: addComma(total.sekou),
      position: { x: 20, y: 74 },
    },
    path: "/images/banner/sekou.png",
    link: `/sekou`,
    preload: true,
  };

  const review: TopSlideBanner = {
    alt: "外構・リフォーム工事をしたお客様の声を公開しています！",
    text: {
      type: "review",
      value: addComma(total.review),
      position: { x: 32, y: 74 },
    },
    path: "/images/banner/review.png",
    link: "/e_list",
  };

  const slides: TopSlideBanner[] = [
    sekou,
    cp_big_carport,
    cp_new_item,
    set_discounts,
    plan,
    contest,
    review,
    mobi,
    campaign,
    mizu,
  ];

  return slides;
}
